import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back to `}<a parentName="p" {...{
        "href": "/Case-studies",
        "title": "Case-studies"
      }}>{`Case-studies`}</a>{``}</p>
    <h3>{`San Juan Island 2021`}</h3>
    <p>{`Conversation among hundreds of local citizens in the San Juan Islands about the Land Bank and issues of land use and conservation.`}</p>
    <h3>{`Links`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://sjclandbank.org/pol-is-community-discussion/"
          }}>{`https://sjclandbank.org/pol-is-community-discussion/`}</a>{` `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://sjclandbank.org/wp-content/uploads/2021/10/Final-Report_-Public-Discussion-on-the-San-Juan-County-Land-Bank-2.pdf"
          }}>{`https://sjclandbank.org/wp-content/uploads/2021/10/Final-Report_-Public-Discussion-on-the-San-Juan-County-Land-Bank-2.pdf`}</a>{` `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://sanjuans.org/"
          }}>{`https://sanjuans.org/`}</a>{` `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Open data: `}<a parentName="p" {...{
            "href": "https://github.com/compdemocracy/openData/tree/master/ssis.land-bank-farmland.2rumnecbeh.2021-08-01"
          }}>{`https://github.com/compdemocracy/openData/tree/master/ssis.land-bank-farmland.2rumnecbeh.2021-08-01`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.sanjuanco.com/DocumentCenter/View/12489/Resolution-13-2017-Adopt-Affordable-Housing-Workgroup-Strategic-Action-Plan?bidId="
          }}>{`https://www.sanjuanco.com/DocumentCenter/View/12489/Resolution-13-2017-Adopt-Affordable-Housing-Workgroup-Strategic-Action-Plan?bidId=`}</a>{` `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.sanjuanco.com/DocumentCenter/View/12215/Effectively-Commenting-on-the-Comp-Plan-2?bidId="
          }}>{`https://www.sanjuanco.com/DocumentCenter/View/12215/Effectively-Commenting-on-the-Comp-Plan-2?bidId=`}</a>{` `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://sanjuans.org/sjc_comp_plan/"
          }}>{`https://sanjuans.org/sjc_comp_plan/`}</a>{` `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.sanjuanco.com/DocumentCenter/View/21254/2020-10-13_PUB_Friends_of_the_San_Juans_UGA_and_Rural_Cluster_Comments_10-13-2020"
          }}>{`https://www.sanjuanco.com/DocumentCenter/View/21254/2020-10-13_PUB_Friends_of_the_San_Juans_UGA_and_Rural_Cluster_Comments_10-13-2020`}</a>{` `}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      